<template>
   <v-row no-gutters>
    <v-col cols="12">
      <v-skeleton-loader v-if="$store.state.documents.retrieveLoader" transition="fade-transition" type="table" />
      <template v-else>
        <v-empty-state v-if="!creditnotesList.length" title="No existen documentos" />
        <template v-else>
          <v-data-table :headers="headers" :items="creditnotesList" :items-per-page="pagination" hide-default-header disable-sort mobile-breakpoint hide-default-footer @click:row="(creditnotesList) => { hasLink ? $router.push({name: creditnotesList.documents.status === 'draft' ? 'DocumentsUpdate' : 'DocumentsRetrieve', params: {id: creditnotesList.documents.id}}) : ''}" style="cursor: pointer">
          <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" @sort="setSort" />
            </template>
            <!-- end header -->
            <!-- <template v-slot:[`item.documents.customer`]="{item}">
              <div class="text-left py-4">
                <v-menu open-on-hover right offset-x :nudge-width="160" transition="slide-x-transition">
                  <template v-slot:activator="{on}">
                    <div v-on="on">
                      <span class="grey-500--text font-weight-medium">{{item.documents.customer.name}}</span>
                    </div>
                  </template>
                  <v-card class="pa-0">
                    <v-subheader class="font-weight-medium background">{{item.documents.customer.name}}</v-subheader>
                    <v-card-text class="pa-0">
                      <v-row class="mx-0">
                        <v-col cols="12" class="pb-0">
                          <span class="grey-500--text font-weight-medium">Emisión</span>
                        </v-col>
                        <v-col cols="4">
                          <span class="d-block">Total</span>
                          <span class="d-block">$ {{item.documents.customer.aggregations.sales | currency(item.documents.currency)}}</span>
                        </v-col>
                        <v-col cols="4">
                          <span class="d-block">{{currentMonth | capitalize}}</span>
                          <span class="d-block">$ {{item.documents.customer.aggregations.sales_current_period | currency(item.documents.currency)}}</span>
                        </v-col>
                        <v-col cols="4">
                          <span class="d-block">Balance</span>
                          <span class="d-block">$ {{item.documents.customer.aggregations.balance | currency(item.documents.currency)}}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider class="mx-3" />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn :to="{name: 'CustomerRetrieveUpdate', params: {id: item.documents.customer.id}}" text color="blue-500">Ver cliente <v-icon small right>mdi-arrow-right</v-icon></v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </template>
            <template v-slot:[`item.document`]="{item}">
              <div class="text-left py-2">
                <span class="d-block">{{item.document.name}}</span>
                <span class="d-block body-2 grey-500--text font-weight-medium">Nº {{item.number}}</span>
              </div>
            </template>
            <template v-slot:[`item.document.date`]="{item}">
              <span>{{item.document.date | date}}</span>
            </template>
            <template v-slot:[`item.documents.document`]="{item}">
              <div class="text-left py-2">
                <v-menu open-on-hover right offset-x :nudge-width="160" transition="slide-x-transition">
                  <template v-slot:activator="{on}">
                    <div v-on="on">
                      <span class="d-block">{{item.documents.document.name}}</span>
                      <span class="d-block body-2 grey-500--text font-weight-medium" v-if="item.documents.status !== 'draft'">Nº {{item.documents.number}} • {{item.documents.date | date}}</span>
                    </div>
                  </template>
                  <v-card>
                    <v-subheader class="font-weight-medium background">{{item.documents.document.name}}</v-subheader>
                    <v-card-text>
                      <v-list-item class="px-0">
                        <v-list-item-title class="grey-500--text font-weight-medium">Productos</v-list-item-title>
                      </v-list-item>
                      <template v-if="item.documents.lines.length">
                        <v-list-item class="px-0 my-1" v-for="p in item.documents.lines.slice(0, 3)" :key="p.id">
                          <v-list-item-title>
                            <span class="d-block">{{p.name}}</span>
                            <span class="d-block font-italic text--disabled">{{p.quantity}} unidades</span>
                          </v-list-item-title>
                          <v-list-item-subtitle class="text-right">{{p.amount | currency(item.documents.currency)}} {{item.documents.currency}}</v-list-item-subtitle>
                        </v-list-item>
                      </template>
                      <v-list-item class="px-0 my-1" v-if="item.documents.lines.length > 3">
                        <v-list-item-title>y otros {{item.documents.lines.length - 3}} productos</v-list-item-title>
                      </v-list-item>
                    </v-card-text>
                    <v-divider class="mx-4" />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn :to="{name: item.documents.status === 'draft' ? 'DocumentsUpdate' : 'DocumentsRetrieve', params: {id: item.documents.id}}" text color="blue-500">Ver detalle <v-icon small right>mdi-arrow-right</v-icon></v-btn>
                       <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </template>
            <template v-slot:[`item.documents.total`]="{item}">
              <div class="text-right">
                <span class="body-2 grey-500--text font-weight-medium">{{item.documents.total | currency(item.documents.currency)}}</span>
                <span class="ml-1">{{item.documents.currency}}</span>
              </div>
            </template>-->
          </v-data-table>
          <template v-if="showPagination || showAll">
            <v-divider />
            <v-row class="mx-0" align="center">
              <v-col cols="12" class="text-center" v-if="showAll">
                <v-btn :to="{name: 'DocumentsList', query: showCriteria}" outlined color="grey-500">Ver todas las facturas</v-btn>
              </v-col>
              <template v-if="showPagination">
                <v-col cols="6">
                  <span class="body-2 grey-500--text">{{count}} registros</span>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-pagination v-model="page" :length="totalPages" total-visible="6" />
                </v-col>
              </template>
            </v-row>
          </template>
        </template>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VEmptyState from '@/components/commons/VEmptyState'
import ListViewMixin from '@/mixins/ListViewMixin'
export default {
  components: {
    VTableHeaders,
    VEmptyState
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    headers: [
      { text: 'Cliente', value: 'documents.customer', class: 'font-weight-bold', sortable: true },
      { text: 'Documento', value: 'document' }
      // { text: 'Fecha', value: 'document.date', align: 'end', sortable: true },
      // { text: 'Anulación', value: 'documents.document' },
      // { text: 'Total', value: 'documents.total', align: 'end', sortable: true }
    ]
  }),
  computed: mapState({
    count: state => state.documents.creditnotesCount,
    creditnotesList: state => state.documents.creditnotesList
  })
}
</script>